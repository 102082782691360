@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "compass-mixins/lib/_compass.scss";
// Fonts
@import "modules/fonts/opensans";
@import "modules/fonts/icons";
// Modules
@import "modules/variables";
@import "modules/mixins/blur";
@import "modules/mixins/center";
// Vendors
@import "vendors/material-design";
// Objects
@import "objects/common/global";
@import "snowly-material-design";
@import "objects/common/header";
// Modules
@import "bootstrap";
@import "vendors/quill";


// Custom snackbar for errors
.error-snackbar{
  background-color: #e5e5e5;
}
