/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-light.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-light.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-light.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-lightitalic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-lightitalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-lightitalic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-lightitalic.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-lightitalic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-regular.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-italic.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-italic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-semibold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-semibold.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-semibold.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-semibold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-semibolditalic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-semibolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-semibolditalic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-semibolditalic.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-semibolditalic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-bold.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-bold.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-bolditalic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-bolditalic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-bolditalic.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-bolditalic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-extrabold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-extrabold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-extrabold.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-extrabold.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-extrabold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/opensans/opensans-extrabolditalic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/opensans/opensans-extrabolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/opensans/opensans-extrabolditalic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-extrabolditalic.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/opensans/opensans-extrabolditalic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 800;
}
