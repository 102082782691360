

@font-face {
  font-family: 'klobal';
  src:  url('/assets/fonts/icons/klobal.eot');
  src:  url('/assets/fonts/icons/klobal.eot#iefix') format('embedded-opentype'),
  url('/assets/fonts/icons/klobal.ttf') format('truetype'),
  url('/assets/fonts/icons/klobal.woff') format('woff'),
  url('/assets/fonts/icons/klobal.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.klobal-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'klobal' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.collection:before {
  content: "\e900";
}
.file:before {
  content: "\e901";
}
.zoom:before {
  content: "\e904";
}
.selectArrow:before {
  content: "\e903";
}
.calendar:before {
  content: "\e902";
}
