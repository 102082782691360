.mat-checkbox {
  margin-right: 15px;
  font-family: $font1;
  .mat-checkbox-inner-container {
    margin-right: 11px;
  }
  .mat-checkbox-frame {
    border-color: map_get($checkbox, bg);
    background-color: map_get($checkbox, bg);
  }
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border-color: map_get($checkbox, bg);
    background-color: map_get($checkbox, bg);
    svg path {
      stroke: map_get($checkbox, stroke) !important;
      stroke-width: 1.5px;
    }
  }
  input.cdk-focused + div + .mat-checkbox-frame {
    border-color: darken(map_get($checkbox, bg), 10%);
  }
  .mat-checkbox-label {
    font-size: 15px;
    font-weight: 700;
  }
}
