.dropdown.ngx-contextmenu {
  font-family: $font1;
  .dropdown-menu {
    padding: 5px 0 6px;
    border-radius: 0;
    border: 1px solid $border;
    border-top: 3px solid $red;
    .dropdown-item {
      padding: 3px 20px 2px;
      font-size: 13px;
      &.disabled {
        color: map_get($hybridInput, hover);
      }
      &.passive {
        background: transparent;
        cursor: default;
      }
      .active {
        background: $red;
        color: $dark;
      }
    }
    a.dropdown-item {
      &:active {
        background: $red;
      }
    }
    span.dropdown-item {
      color: $dark;
      &:active {
        color: $dark;
      }
    }
  }
  .dropdown-divider {
    margin: 5px 0;
  }
}
