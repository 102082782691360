
.show-menu-dropdown .collapse {
  display: block;
}

.header {
  background: map_get($header, bg);
  &.fixed-top {
    z-index: 950;
  }
  a {
    color: map_get($header, text);
    text-decoration-line: none;
  }
  .navbar {
    padding-left: 20px;
    padding-right: 12px;
  }
  .navbar-brand {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 30px;
    img {
      height: 35px;
    }
  }
  .list-inline {
    margin: 0;
    .list-inline-item:not(:last-child) {
      margin-right: 15px;
    }
    .list-inline-item {
      &.active, &.forceActive {
        a {
          color: map_get($header, text);
          border-color: map_get($header, linkActiveBorder);
        }
      }
      &.settings {
        > a {
          cursor: pointer;
          &:active, &:hover, &:focus {
            color: map_get($header, text);
          }
        }
        .dropdown-menu {
          .dropdown-item {
            border-top: none;
            &.active {
              background: map_get($header, messageBg);
            }
          }
        }
      }
    }
    a {
      display: inline-block;
      padding: 13px 10px 14px;
      color: rgba(255, 255, 255, .8);
      font-size: 14px;
      font-weight: 500;
      border-top: 2px solid transparent;
      @include single-transition(all linear .2s);
    }
  }
  .mat-icon-button {
    margin-right: 19px;
  }

  .navbar-nav {
    a, .messageLink, .help {
      color: map_get($header, text);
    }
    .nav-item .nav-link:after {
      display: none;
    }

    //Help
    .help {
      font-size: 20px;
      font-weight: 600;
      position: relative;
      top: 1px;
    }

    // Messages
    .messages {
      width: 430px;
      margin: 0;
      padding: 0;
      right: -20px;
      > ul {
        overflow-y: auto;
        max-height: 356px;
      }
      img {
        margin-left: 20px;
        border-radius: 50%;
      }
      .media {
        border-bottom: 1px solid map_get($header, messageBorder);
        padding: 10px 0;
      }
      > li:last-child {
        border-bottom: none;
      }
      .media-body {
        padding-top: 6px;
        max-width: calc(100% - 86px);
        > .row {
          margin-right: 6px;
        }
      }
      h5 {
        font-size: 13px;
        font-weight: 700;
        overflow: hidden;
        height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .small {
        font-size: 12px;
        position: relative;
        top: -1px;
      }
      .last {
        font-size: 13px;
        padding-right: 20px;
        overflow: hidden;
        height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        margin-bottom: 0;
      }
    }
    // User
    .user {
      > a {
        padding: 5px 8px 5px 0;
        img {
          margin-right: 16px;
          margin-left: 16px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }
    }

    .nav-link {
      line-height: 14px;
      text-align: left;
      small {
        line-height: 11px;
      }
    }

    // Division
    .division {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 20px;
        position: absolute;
        top: 15px;
        left: 0;
        background: map_get($header, separator);
      }
      > a.nav-link {
        padding: 13px 16px 14px 16px;
        .fa {
          margin-right: 8px;
        }
      }
    }

    .user, .division {
      a {
        display: inline-block;
        color: map_get($header, text);
        font-size: 14px;
        font-weight: 500;
        &:active, &:hover, &:focus {
          color: map_get($header, text);
        }
        > * {
          display: inline-block;
          vertical-align: middle;
        }
        > div {
          margin-right: 3px;
          line-height: 10px;
        }
        small {
          color: map_get($header, small);
          font-size: 9px;
          text-transform: uppercase;
          font-style: italic;
          font-weight: 900;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .newTask {
    margin-right: 10px;
    position: relative;
  }

  .divider_right {
    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: -12px;
      background: map_get($header, separator);
    }
  }

  a.mat-button {
    height: 49px;
    padding-top: 4px;
    border-radius: 0 !important;

    .mat-button-wrapper {
      font-weight: 500;
      font-size: 14px;

      mat-icon:first-child {
        margin-right: 10px;
        margin-left: 0;
      }

      mat-icon:last-child {
        margin-left: 10px;
        margin-right: 0;
      }
    }

    .mat-button-ripple {
      border-radius: inherit !important;
    }
  }
}

.user-mat-menu {
  .mat-menu-content {
    button {
      &.logout {
        cursor: pointer;

        &:active, &:hover, &:focus {
          color: map_get($header, text);
          background: map_get($header, small);
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar .navbar-nav, .navbar-brand + .collapse > ul:first-child {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: center;
  }
  .navbar-brand + .collapse > ul:first-child {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .navbar .navbar-nav, .navbar-brand + .collapse > ul:first-child {
    flex-direction: column;
    text-align: center;
  }
  .header .list-inline .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
  .header {
    .newTask {
      margin: 15px 0;
      &:before {
        display: none;
      }
      .mat-icon-button {
        margin: 0;
      }
    }
    .nav-item .nav-link {
      &:before {
        display: none;
        width: 30px;
      }
    }
    .nav-item.user {
      margin: 0 0 10px;
    }
    .navbar-nav .division > a.nav-link {
      padding-left: 0;
    }
  }
}
