
$badgeSize: 14px;

.fileAvatar {
  padding: 6px 15px; max-width: 140px; background: $blue; position: absolute; border-radius: 3px; @include box-shadow(0 0 10px 0px rgba(0, 0, 0, 0.3));
  .badge {
    height: $badgeSize; min-width: $badgeSize; padding: 0 2px; line-height: 14px; text-align: center; font-size: 9px; font-weight: 700;
    background: $red; border-radius: 4px; position: absolute; top: -($badgeSize * 0.5); right: -($badgeSize * 0.5); @include box-shadow(0 0 6px 0px rgba(0, 0, 0, 0.3));
  }
  .name {font-size: 11px; font-weight: 600; color: $lightText; max-height: 32px; overflow: hidden;}
}
