@import "../../../styles/modules/variables";

button {
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  .mat-button-wrapper {
    opacity: 1;
    transition: all 0.2s ease;
  }
  &.spinner {
    .mat-button-wrapper {
      opacity: 0;
      transition: all 0.2s ease;
    }
  }
  &.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #353535;
    animation: spinner .8s linear infinite;
  }
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-mini-fab, .mat-fab {
  &:focus {
    outline: none;
  }
}

.mat-raised-button, .mat-button {
  .mat-button-wrapper {
    font-size: 13px;
    font-weight: 700;
  }
}

.mat-raised-button, .mat-button, .mat-fab, .mat-mini-fab, .mat-icon-button, .mat-stroked-button {
  &[disabled] {
    cursor: not-allowed !important;
  }
}

.mat-button-focus-overlay, .mat-button-ripple, .mat-raised-button, .mat-button, .mat-stroked-button {
  border-radius: 14px !important;
}

.mat-icon-button .mat-button-ripple {
  border-radius: 50% !important;
}

.mat-fab, .mat-mini-fab, .mat-raised-button, .mat-fab:not([disabled]):active, .mat-mini-fab:not([disabled]):active, .mat-raised-button:not([disabled]):active {
  @include box-shadow(none !important);
}

.mat-mini-button {
  line-height: 30px !important;
}

.mat-icon-button {
  &.mat-mini-button .mat-icon {
    font-size: 21px;
    width: 21px;
    height: 21px;
    position: relative;
    top: -1px;
  }
}

.mat-button-base {
  .mat-button-wrapper {
    .mat-icon.mat-icon-sm {
      font-size: 17px;
      width: 17px;
      height: 17px;
    }
  }
}

.mat-button.reverse {
  border: 1px solid;
  &.mat-primary {
    border-color: $red;
    &:active, &:hover, &:focus {
      .mat-button-focus-overlay {
        background-color: $red;
        opacity: 1;
      }
      .mat-button-wrapper {
        color: $lightText;
        position: relative;
        z-index: 1;
      }
    }
    &[disabled] {
      border-color: rgba(0, 0, 0, 0.12);
      .mat-button-focus-overlay {
        background-color: rgba(0, 0, 0, 0.12);
        opacity: 1;
      }
      .mat-button-wrapper {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}
