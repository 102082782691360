.mat-dialog-container {
  overflow: hidden !important;
  max-width: 94vw !important;
  position: relative;

  mat-toolbar {
    background: transparent;
    height: auto;
    margin-bottom: 15px;
    padding: 0;

    .mat-icon-button {
      .mat-button-focus-overlay {
        display: none;
      }
    }
  }
}

mat-toolbar {
  .flex {
    flex: 1 1 auto;
  }
  .mat-dialog-title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  .mat-icon-button {
    color: $dark;
    margin-right: 0px;
  }
}

.mat-dialog-container {
  position: relative;
  .mat-dialog-content {
    padding: 0 33px;
  }
  .mat-dialog-actions {
    display: block;
    padding: 24px 0;
    text-align: center;
  }
}
