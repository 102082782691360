@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

/* Variables */

// Fonts
$font1: 'Open Sans';

// Selection
$selection: #ffdbde;
$active: #f3f3f3;

// Card
$card-bg: #fff;

// Table
$table-bg: #0000;

// Basic colors
$bg: #eaeaea;
$contentBg: #fff;
$text: #000;
$lightText: #fff;
$red: #dd2235;
$green: #4caf50;
$blue: #008de7;
$darkBlue: #525364;
$orange: #ff9800;
$yellow: #ffd600;
$dark: #262735;
$border: #eaeaea;
$placeholder: #d0d0d0;
$selectColor: #262735;
$selectPlaceholder: #878787;
$attachmentIcon: rgba(82, 83, 100, .39);
$partner: #383a4d;
$muted: $secondary;

$primary: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;

$body-bg: $bg;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1630px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1570px
);

//Header map
$header: (
  bg: #262735,
  text: #fff,
  linkActiveBorder: #dd2235,
  border: #262735,
  small: #dd2235,
  separator: #525364,
  messagetText: #000,
  mutedMessagetText: #3d3d3d,
  messageBg: #f7f7f9,
  messageBorder: #e0e0e0
);

$listBar: (
  bg: #fff,
  icon: #ececec,
  iconHover: #dcdcdc,
  separator: #ececec,
);

$tabs: (
  bg: rgba(208, 208, 208, .29),
  active: #fff,
  bodyBg: #fff,
);

$hybridInput: (
  border: #c1c1c1,
  hover: #b3b3b3,
);

$attachment: (
  border: #d5d5d5,
  emptyColor: #e0e0e0,
  emptyBorder: $border,
  controlBg: rgba(38, 39, 53, .6),
  controlIcon: #fff,
  pages: #d5d5d5,
  pagesCurrent: $dark,
  addCategory: #eaeaea,
  addCategoryHover: #d5d5d5,
  addCategoryTextHover: $darkBlue,
);

$input: (
  disabledBg: #f3f3f3,
  disabledSelectArrow: #9c9c9c,
  disabledSelectText: rgba(0, 0, 0, 0.42),
);

$checkbox: (
  bg: $border,
  stroke: $dark,
);

//Material own palette
$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-12-opacity: rgba(black, 0.12);
$white-12-opacity: rgba(white, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);

$own-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #e84c5b,
  500: #dd2235,
  600: #d2172a,
  700: #c80b1e,
  800: #b90416,
  900: #a80213,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$own-dark: (
  50: #fff,
  100: #ececec,
  200: #838596,
  300: #626478,
  400: #484b61,
  500: #262735,
  600: #2c2e42,
  700: #222438,
  800: #181a2d,
  900: #0e0f20,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: #ececec,
    100: $black-87-opacity,
    200: white,
    300: white,
    400: white,
    500: white,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  )
);
