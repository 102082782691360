
.datepickerWithBorder {
  .mat-form-field-flex {
    border: 1px solid $border;
    padding-left: 10px;
    padding-right: 10px;
  }
  .mat-form-field-infix {
    border-top: none;
    padding: 0.688em 0;
  }
  .mat-form-field-placeholder {
    top: 1.56em;
  }
  .mat-form-field-underline {
    opacity: 0;
  }
}
