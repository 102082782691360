@import "../../modules/variables";

// html, body {
//   height: 100%;
// }

::-moz-selection {
  background: $selection;
  text-shadow: none;
}

::selection {
  background: $selection;
  text-shadow: none;
}

body {
  font-family: $font1;
  color: $text;
  background: $bg;
}

a {
  &:active, &:hover, &:focus {
    text-decoration: none;
  }
}

hr {
  margin: 14px 0;
  background-color: rgba(0, 0, 0, .2) !important;
}

h1 {
  font-size: 22px;
}

h4 {
  margin-top: 40px;
}

.label {
  margin: 0;
  font-weight: 700;
  font-size: 14px;

  &.required:before {
    content: '*';
    margin-right: 5px;
    color: $danger;
  }
}

.formItem {
  display: inline-block;
  margin-right: 26px;
  margin-bottom: 10px;
  vertical-align: top;
  &.full-width {
    width: 100%;
  }
  .mat-select {
    margin-bottom: 20px;
  }
}

.textarea {
  max-width: 100%;
}

.mat-form-field {
  width: 330px;

  &.input-auto-width {
    width: auto;
  }
  &.input-full-width {
    width: 100%;
  }
  &.input-small-width {
    width: 240px;
  }
  &.input-smaller-width {
    width: 165px;
  }
  &.input-no-min-width {
    min-width: auto;
  }
}

mat-select.mat-select {
  padding-top: 0;
  width: 220px;
}

.hide {
  display: none !important;
}

.mat-select-panel {
  margin: 6px 0 0 11px;
}

// fix scrolling issue for Chrome (AttachmentDialogComponent) - not working
.hide-overflow-test-class {
    overflow: hidden;
}

// fix scrolling issue for Chrome (AttachmentDialogComponent), 2nd attempt
// taken from angular-material
.cdk-global-scrollblock2 {
  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;

  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;
}
