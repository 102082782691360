
.tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    padding: 8px 10px;
    background-color: $contentBg;
    color: $text;
    font-family: $font1;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  }
  &.tooltip-bottom {
    margin-top: 8px;
    .tooltip-inner:before {
      border-bottom-color: $contentBg;
    }
  }
  &.tooltip-top {
    .tooltip-inner:before {
      border-top-color: $contentBg;
    }
  }
}

.mat-tooltip {
  font-size: 14px;
}
