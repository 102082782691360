
.mat-select {
  font-family: $font1;
  .mat-select-trigger {
    height: 46px;
    padding-left: 16px;
    padding-right: 6px;
  }
  .mat-select-value {
    padding-left: 16px;
  }
  .mat-select-underline {
    bottom: 2px;
  }
  .mat-select-value-text {
    font-size: 14px;
    font-weight: 600;
  }
  .mat-select-placeholder {
    top: -1px;
    font-size: 14px;
    font-weight: 400;
    color: $selectPlaceholder;
  }
  .mat-select-arrow {
    position: relative;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid;
    color: $selectColor;
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      left: -6px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid;
      color: white;
    }
  }
  &.select-full-width {
    width: 100%;
  }
  &.select-auto-width {
    width: auto;
  }
}
.mat-select-panel {background: #fff;}

.mat-option {
  font-family: $font1;
  font-size: 14px;
  font-weight: 600;
}

.selectWithBorder {
  .mat-select-trigger {
    border: 1px solid $border;
    padding-left: 10px;
    padding-right: 10px;
    height: 39px;
  }
  .mat-select-value {
    padding-left: 0px;
    padding-top: 10px;
  }
  .mat-select-underline {
    display: none;
  }
  &.mat-select-invalid .mat-select-trigger {
    border-color: $red;
  }
}
