.mat-menu-panel {
  min-height: 42px !important;

  .mat-menu-content {
    a, a:hover {
      color: inherit;
    }
    .mat-menu-item {
      height: 36px;
      line-height: 36px;
      &.active {
        background: $active;
      }
      a, a:hover {
        color: inherit;
      }
      mat-icon {
        font-size: 20px;
        height: 24px;
        width: 20px;
      }
      mat-icon:not(:first-child) {
        margin-left: 16px;
      }
      mat-icon:last-child {
        margin-right: 0;
      }
      &[disabled="true"] {
        color: $muted;
        cursor: not-allowed;
        pointer-events: all !important;
      }
    }
    .mat-menu-sub-item {
      padding: 4px 20px;
      font-size: 12px;
      font-weight: 400;
      color: #3d3d3d;
      height: 32px !important;
      line-height: 24px !important;
      mat-icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
