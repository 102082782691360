@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import "../../styles/modules/variables";

.ql-container {
  font-size: 14px;
}

.ql-disabled.ngx-quill-view .ql-editor {
  padding: 8px 0 8px 0;
}

.ql-toolbar.ql-snow {
  border: 1px solid $border-color;
  border-bottom: 0;
  background: $gray-100;
}

quill-editor {
  .ql-container.ql-snow {
    border: none;

    .ql-editor {
      border: 1px solid $border-color;
      transition: border-color linear 0.2s;

      &:focus {
        transition: border-color linear 0.2s;
        border-color: $selectColor;
      }
    }
  }
}

quill-view.no-padding .ql-disabled.ngx-quill-view .ql-editor {
  padding: 0;
}
